.general-md-editor {
  font-size: 0.875rem;
  text-align: justify;
  padding: 1rem;
  padding-top: 0.5rem;
}

.general-md-editor textarea {
  font-size: 0.8rem;
  font-family: "source-code-pro", "Menlo", "Monaco", "Consolas", "Courier New", monospace;
}

.general-md-editor .ProseMirror {
  outline: none;
}

.general-md-editor a[href]:after {
  content: "" !important;
}

.general-md-editor abbr[title]:after {
  content: " " !important;
}

.general-md-editor h1,
.general-md-editor h2,
.general-md-editor h3,
.general-md-editor h4,
.general-md-editor h5,
.general-md-editor h6 {
  font-weight: bold;
}

.general-md-editor table {
  border-collapse: collapse;
  border-spacing: 0;
}

.general-md-editor th,
.general-md-editor td {
  padding-left: 1em;
  padding-right: 1em;
  border: 1px dashed #c8c8c8;;
}

.general-md-editor th {
  color: #3d3d3d;
  background-color: #d1bf92;
  font-weight: bold;
}

.general-md-editor tr:nth-child(odd) {
  background: #eee;
}

.general-md-editor li p {
  margin: 0;
}

.general-md-editor p {
  widows: 2;
  orphans: 2;
}

.general-md-editor blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
}
