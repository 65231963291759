.policy-md-editor {
  font-family: "Open Sans", "Arial", "sans-serif";
  font-size: 1rem;
  text-align: justify;
  padding: 1rem;
  padding-top: 0.5rem;
  margin: 0.5rem;
}

.policy-md-editor textarea {
  font-size: 0.8rem;
  font-family: "source-code-pro", "Menlo", "Monaco", "Consolas", "Courier New", monospace;
}

.policy-md-editor .ProseMirror {
  outline: none;
}

/* Override ProseMirror's 'white-space: break-spaces/pre'
else upon initial render the \n's in the markdown get rendered as breaks;
unfortunately a side-effect is that spaces may be eaten in Firefox:
*/
.policy-md-editor p {
  white-space: normal;
}

.policy-md-editor a[href]:after {
  content: "" !important;
}

.policy-md-editor abbr[title]:after {
  content: " " !important;
}

.policy-md-editor h1 {
  border-bottom-width: medium;
  border-bottom-style: solid;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px 0;
  border-bottom-color: rgba(153, 46, 23, 0.36);
}

.policy-md-editor h1:not(:nth-of-type(1)) {
  page-break-before: always;
}

.policy-md-editor h1,
.policy-md-editor h2,
.policy-md-editor h3,
.policy-md-editor h4,
.policy-md-editor h5,
.policy-md-editor h6 {
  color: #992e17;
  font-weight: bold;
}

.policy-md-editor table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* ProseMirror's tables don't include theads, but this was the styling from the original in-app view */
/* .policy-md-editor thead {
  background-color: #d1bf92;
  font-weight: bold;
  color: #3d3d3d;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
} */

.policy-md-editor th,
.policy-md-editor td {
  padding-left: 1em;
  padding-right: 1em;
  border: 1px dashed #c8c8c8;;
}

.policy-md-editor th {
  color: #3d3d3d;
  background-color: #d1bf92;
  font-weight: bold;
}

/* ProseMirror's tables don't include tbody, though the browser may insert one by default */
/* .policy-md-editor tbody tr:nth-child(even) { */
.policy-md-editor tr:nth-child(odd) {
  background: #eee;
}

.policy-md-editor li p {
  margin: 0;
}

.policy-md-editor p {
  widows: 2;
  orphans: 2;
}

.policy-md-editor blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
}
